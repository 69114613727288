import { createRouter, createWebHashHistory } from 'vue-router'
import Riepilogo from '../views/Riepilogo.vue'
import Associa from '../views/Associa.vue'
import LoginForm from '../views/LoginForm.vue'
import Login from '../views/Login.vue'
import MailLogin from '../views/MailLogin.vue'
import Mail from '../views/Mail.vue'
import PageError from '../views/PageError.vue'

const routes = [
  {
    path: '/',
    name: 'Riepilogo',
    component: Riepilogo,
    meta: {title:'Riepilogo foto'}
  },
  {
    path: '/associa',
    name: 'Associa',
    component: Associa,
    meta: {title:'Associa foto'}
  },
  {
    path: '/login-form',
    name: 'LoginForm',
    component: LoginForm,
  },
  {
    path: '/login/:jwt',
    name: 'Login',
    component: Login,
  },
  {
    path: '/mail-login',
    name: 'MailLogin',
    component: MailLogin,
  },
  {
    path: '/mail',
    name: 'Mail',
    component: Mail,
    meta: {title:'Invio mail'}
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Error',
    component: PageError,
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
