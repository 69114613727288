<template>
  <!-- <img src="../assets/img/404.png" alt="Error 404"> -->
  <div class="error-container">
    <div class="text-h1 secondary--text mb-5">¯\_(ツ)_/¯</div>
    <h1>Pagina non trovata</h1>
    <v-btn x-large color="primary" to="/"> Torna al portale </v-btn>
  </div>
</template>

<script>
export default {
  name: "PageError",
};
</script>

<style scoped>
.error-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>