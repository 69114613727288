import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import { loadFonts } from './plugins/webfontloader'

loadFonts()

var app = createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)

const axiosInstance = axios.create()
app.config.globalProperties.axios = { ...axiosInstance }

app.mount('#app')

