// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { en, it } from 'vuetify/locale'

// Vuetify
import { createVuetify } from 'vuetify'

const myCustomLightTheme = {
  dark: false,
  colors: {
    primary: '#77Af75',
    secondary: '#B0BEC5',
    accent: '#8c9eff'
  },
}

export default createVuetify(
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
  {
    theme: {
      defaultTheme: 'myCustomLightTheme',
      themes: {
        myCustomLightTheme,
      },
    },
    locale: {
      locale: 'it',
      fallback: 'en',
      messages: { it, en }
    },
    defaults: {
      VTextField: {
        variant: 'underlined',
        color: 'primary'
      },
      VSelect: {
        clearable: true,
        variant: 'underlined',
        color: 'primary'
      },
      VCombobox: {
        clearable: true,
        variant: 'underlined',
        color: 'primary'
      },
      VTextarea: {
        clearable: true,
        variant: 'underlined',
        color: 'primary'
      },
      VCheckbox: {
        color: 'primary'
      },
      VCardActions: {
        VBtn: { variant: 'flat' },
      },
      VCardTitle: {
        style: "white-space: unset"
      },
      VDateInput: {
        variant: 'underlined',
        color: 'primary',
        placeholder: "dd/mm/yyyy"
      },
    },
  }
)
