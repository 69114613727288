import { createStore } from 'vuex'

export default createStore({
  state: {
    selectedPhotos: [],
    background: '',
    background_style: {},
    logo: '',
    link_tracking: '',
    cd_azienda: '',
    username: '',
    documento: '',
    ambiente: '',
    ambienti: [],
    loaded: true,
    compila_associa: null,
    endpoint: 'https://mclick.newtonsrl.eu/',
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
