<template>
  <v-container style="height: 100vh" class="pb-0">
    <v-row
      class="align-center fill-height"
      :class="
        $store.state.ambiente != 'A' && $store.state.ambiente != ''
          ? 'r-height'
          : ''
      "
    >
      <v-col cols="12" md="5" class="pa-0 fill-height">
        <v-responsive class="pt-5 pb-3 px-4 fill-height">
          <v-card height="100%" class="d-flex flex-column">
            <v-card-title>Mail:</v-card-title>
            <v-card-text style="flex-grow: 1; overflow: auto">
              <v-form v-model="valid" ref="form">
                <v-select
                  label="Mittente"
                  v-model="selectedMittente"
                  :items="mittente"
                  :rules="mitRules"
                ></v-select>
                <v-combobox
                  v-model="selectedDestinatario"
                  :items="destinatario"
                  label="Destinatario"
                  multiple
                  :rules="destRules"
                >
                  <template v-slot:chip="data">
                    <v-chip
                      :key="JSON.stringify(data.item)"
                      v-bind="data.props"
                      size="small"
                      closable
                    >
                      <template v-slot:prepend>
                        <v-avatar
                          class="bg-primary text-uppercase"
                          start
                        >{{ data.item.title.slice(0, 1) }}</v-avatar>
                      </template>
                      {{ data.item.title }}
                    </v-chip>
                  </template>
                </v-combobox>
                <v-combobox
                  v-model="selectedCC"
                  :items="destinatario"
                  label="CC"
                  multiple
                  :rules="ccRules"
                >
                  <template v-slot:chip="data">
                    <v-chip
                      :key="JSON.stringify(data.item)"
                      v-bind="data.props"
                      size="small"
                      closable
                    >
                      <template v-slot:prepend>
                        <v-avatar
                          class="bg-primary text-uppercase"
                          start
                        >{{ data.item.title.slice(0, 1) }}</v-avatar>
                      </template>
                      {{ data.item.title }}
                    </v-chip>
                  </template>
                </v-combobox>
                <v-text-field
                  label="Mail per inoltro interno"
                  v-model="bcc"
                  :suffix="domain"
                  :rules="[
                    (v) => !!v || 'Campo obbligatorio',
                    (v) => checkMail(v) || 'Mail non valida',
                  ]"
                ></v-text-field>
                <v-text-field label="Oggetto" v-model="oggetto"></v-text-field>
                <v-textarea label="Messaggio" v-model="messaggio"> </v-textarea>
              </v-form>
            </v-card-text>
            <v-card-actions class="justify-center flex-column">
              <div v-if="checkedPhotos.length == 0" class="text-red">
                Seleziona almeno una foto
              </div>
              <div v-if="checkDimensioni" class="text-red">
                Dimensioni delle foto superano 15MB
              </div>
              <div v-if="checkedPhotos.length > 50" class="text-red">
                Selezionare un massimo di 50 foto
              </div>
              <div>
                <v-btn
                  :disabled="
                    !valid ||
                    loading ||
                    checkedPhotos.length == 0 ||
                    checkedPhotos.length > 50
                  "
                  :loading="loading"
                  color="primary"
                  @click="sendMail"
                >
                  Conferma
                </v-btn>
              </div>
            </v-card-actions>
          </v-card>
        </v-responsive>
      </v-col>
      <v-col cols="12" md="7" class="pa-0 fill-height d-flex flex-column">
        <v-container>
          <v-row>
            <v-col cols="12" class="d-flex justify-space-between align-center">
              <v-card-title>Foto:</v-card-title>
              <v-checkbox
                :hide-details="true"
                v-model="selectPics"
                color="primary"
                @update:model-value="selectAll()"
                label="Seleziona tutte"
                class="mt-0"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-container>
        <v-responsive
          class="text-center pa-2 overflow-y-auto"
          style="flex: 1"
        >
          <v-lazy
            v-model="isActive"
            :options="{
              threshold: 0.5,
            }"
            min-height="200"
            transition="fade-transition"
          >
            <v-container class="pr-0">
              <v-row>
                <v-col
                  v-for="(photo, i) in photos"
                  :key="photo['difile']"
                  cols="12"
                  lg="4"
                  md="6"
                  sm="12"
                  xs="12"
                >
                  <v-card
                    class="mx-auto text-left"
                    max-width="344"
                    @click="openDialogFullScreen(photo)"
                  >
                    <v-img
                      height="200px"
                      contain
                      :src="photo['link']"
                      class="bg-grey-darken-3"
                      style="position: relative"
                    >
                      <v-checkbox-btn
                        v-model="checkedPhotos"
                        color="primary"
                        :value="photo['difile']"
                        :id="i.toString()"
                        @click="propagation($event)"
                        style="position: absolute; right: 4px; top: 4px"
                      ></v-checkbox-btn>
                    </v-img>
                    <v-card-title>{{ photo["difile"] }}</v-card-title>

                    <v-card-text style="height: 100px;color: rgba(0,0,0,.6)" class="text-subtitle-1 d-flex flex-column">
                      <p class="ma-0 pa-0">Data e ora: {{ photo["didtai"] }}, {{ photo["dihmai"] }}</p>
                      <p class="ma-0 pa-0">Utente: {{ photo["dicdop"] }}</p>
                      <p class="ma-0 pa-0 ellipsis" v-if="photo['diarxx']">Inviata a: {{ photo["diarxx"] }}</p>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-lazy>
        </v-responsive>
      </v-col>
    </v-row>

    <!-- POPUP DETTAGLIO -->
    <v-dialog v-model="image_fullscreen_dialog">
      <PhotoDetail ref="photoDetail" :photo="selectedImage"/>
      <v-btn 
        class="x-button" 
        icon="mdi-close" 
        variant="text"
        @click="image_fullscreen_dialog = false"
        height="40px"
        width="40px"
      />
    </v-dialog>

    <!-- ESITO MAIL -->
    <v-dialog v-model="confermaInvio" max-width="400" persistent>
      <v-card>
        <v-card-text class="d-flex align-center flex-column">
          <v-icon :icon="alertType == 'success' ? 'mdi-check-circle' : 'mdi-alert-circle'" style="font-size: 60px" class="mb-5" :color="alertType" />
          <span style="text-align: center">{{ text }}</span>
        </v-card-text>
        <v-card-actions class="d-flex align-center">
          <v-btn color="primary" width="100%" @click="alertType == 'success' ? closeWindow() : (confermaInvio = false)">
            Chiudi
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import router from "../router";
import PhotoDetail from '../components/PhotoDetail.vue';
// http://localhost:8080/#/mail-login?jwt=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoiMjIwQ0xOTTAwMSIsImNkX2F6aWVuZGEiOiJDYWxvbmlncm91cGFnZSJ9.5LM5m-KTTDSVxvfVH5D7c0veWSlMrLimhDHlkp7TI7M&ambiente=T&doc=202222000003683
// https://mclickimg.newtonsrl.eu/#/mail-login?jwt=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoiMjIwQ0xOTTAwMSIsImNkX2F6aWVuZGEiOiJDYWxvbmlncm91cGFnZSJ9.5LM5m-KTTDSVxvfVH5D7c0veWSlMrLimhDHlkp7TI7M&ambiente=T&doc=202222000003683
// https://mclickimg.newtonsrl.eu/#/mail-login?jwt=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoiMjIwQ0xOTTAwMSIsImNkX2F6aWVuZGEiOiJOZXd0b24ifQ.LZt02TyaL5mhlCe5vyud85LDwIsBtnXTJ4GlNfzBKng&ambiente=T&doc=201760100018463

export default {
  name: "Mail",
  components: {
    PhotoDetail
  },
  data: () => ({
    isActive: false,
    barcodes: [],
    photos: [],
    checkedPhotos: [],
    destinatario: [],
    messaggio: "",
    selectedMittente: null,
    selectedDestinatario: [],
    selectedCC: [],
    mittente: [],
    oggetto: "",
    bcc: "",
    domain: "",
    valid: true,
    selectPics: false,
    selectedImage: null,
    image_fullscreen_dialog: false,
    confermaInvio: false,
    alertType: "",
    text: "",
    loading: false,
    checkDimensioni: false,
    mitRules: [
      (v) => !!v || "Campo obbligatorio",
      (v) => /.+@.+\..+/.test(v) || "Mail non valida",
    ],
    destRules: [
      (v) => v.length > 0 || "Campo obbligatorio",
      (v) => {
        var destCheck = true;
        for (let i = 0; i < v.length; i++) {
          if (!/.+@.+\..+/.test(v[i])) {
            destCheck = false;
          }
        }
        if (destCheck) {
          return true;
        } else {
          return "Mail non valida";
        }
      },
    ],
    ccRules: [
      (v) => {
        var ccCheck = true;
        for (let i = 0; i < v.length; i++) {
          if (!/.+@.+\..+/.test(v[i])) {
            ccCheck = false;
          }
        }
        if (ccCheck) {
          return true;
        } else {
          return "Mail non valida";
        }
      },
    ],
  }),
  mounted() {
    if (this.$store.state.selectedPhotos.length != 0) {
      this.photos = this.$store.state.selectedPhotos;
      // for (var photo of this.photos) {
      //   this.checkedPhotos.push(photo["difile"]);
      // }
    } else if (
      this.$store.state.selectedPhotos.length == 0 &&
      sessionStorage.getItem("ambiente") == "Y"
    ) {
      router.push("/");
    } else {
      this.getFoto();
    }
    this.getMail();
  },
  methods: {
    getFoto() {
      this.axios
        .get(
          `${this.$store.state.endpoint}foto?cd_azienda=${this.$store.state.cd_azienda}&documento=${this.$store.state.documento}&ambiente=${this.$store.state.ambiente}&tipo_barcode=${this.$store.state.tipo_barcode}`
        )
        .then((response) => {
          this.barcodes = Object.keys(response.data);
          for (const element of this.barcodes) {
            for (var photo of response.data[element]) {
              photo["didtai"] = this.formatDate(photo["didtai"]);
              photo["dihmai"] = this.formatTime(photo["dihmai"]);

              this.photos.push(photo);
              // this.checkedPhotos.push(photo["difile"]);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    formatDate(date) {
      date = date.toString();
      var year = date.slice(0, 4);
      var month = date.slice(-4, -2);
      var day = date.slice(-2);
      return `${day}/${month}/${year}`;
    },
    formatTime(time) {
      time = time.toString().padStart(6, "0");
      time = time.replace(/.{2}/g, "$&:");
      time = time.substr(0, time.length - 1);
      return time;
    },
    getMail() {
      this.axios
        .get(
          `${this.$store.state.endpoint}mail?documento=${this.$store.state.documento}&ambiente=${this.$store.state.ambiente}`
        )
        .then((response) => {
          this.mittente = response.data.mittente;
          this.destinatario = response.data.destinatario;
          this.domain = `@${this.mittente[0].substring(
            this.mittente[0].indexOf("@") + 1
          )}`;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    checkMail(mail) {
      // /^(([^\'<>()\[\]\\.,;:\s@"]+(\.[^\'<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
      var regexEmail =
        /^(([^'<>()[\]\\.,;:\s@"]+(\.[^'<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
      return regexEmail.test(mail + this.domain);
    },
    openDialogFullScreen(photo) {
      this.selectedImage = photo;
      this.image_fullscreen_dialog = true;
      this.$nextTick(() => {
        this.$refs.photoDetail.updateDetail()
      })
    },
    back() {
      this.$store.state.selectedPhotos = null;
      router.push("/");
    },
    propagation(event) {
      event.stopPropagation();
      this.$nextTick(() => {
        if (this.checkedPhotos.length == this.photos.length) {
          this.selectPics = true;
        } else {
          this.selectPics = false;
        }
      });
    },
    selectAll() {
      this.checkedPhotos = [];
      if (this.selectPics) {
        for (var photo of this.photos) {
          this.checkedPhotos.push(photo["difile"]);
        }
      }
    },
    sendMail() {
      this.loading = true;
      var dimension = [];

      this.photos.forEach((element) => {
        for (let i = 0; i < this.checkedPhotos.length; i++) {
          if (element["difile"] == this.checkedPhotos[i]) {
            dimension.push(element["dimensioni"]);
          }
        }
      });

      var total = 0;
      for (let i = 0; i < dimension.length; i++) {
        total += dimension[i];
      }
      
      if (total <= 15000000) {
        this.checkDimensioni = false;
        this.axios
          .post(`${this.$store.state.endpoint}mail`, {
            ambiente: sessionStorage.getItem("ambiente"),
            mittente: this.selectedMittente,
            destinatario: this.selectedDestinatario,
            cc: this.selectedCC,
            bcc: `${this.bcc}${this.domain}`,
            // bcc: `${this.bcc}@newtonsrl.eu`,
            oggetto: this.oggetto,
            messaggio: this.messaggio,
            foto: this.checkedPhotos,
            documento: this.$store.state.documento,
          })
          .then(() => {
            this.selectedMittente = null;
            this.selectedDestinatario = [];
            this.selectedCC = [];
            this.bcc = "";
            this.oggetto = "";
            this.messaggio = "";
            this.loading = false;
            this.alertType = "success";
            this.text = "La mail è stata inviata con successo";
            this.confermaInvio = true;
          })
          .catch((error) => {
            console.log(error);
            this.alertType = "error";
            this.text = "Impossibile inviare la mail. Verificare gli indirizzi mail inseriti.";
            this.confermaInvio = true;
            this.loading = false;
          });
      } else {
        this.checkDimensioni = true;
        this.loading = false;
      }
    },
    closeWindow() {
      this.confermaInvio = false;
      window.open("", "_self", "");
      window.close();
    },
  },
};
</script>

<style scoped>
.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.r-height {
  min-height: 100vh;
}
</style>