<template>
  <v-container ref="scrollComponent">
    <v-row>
      <div class="actions d-flex flex-column justify-center">
        <v-btn
          color="primary"
          rounded
          size="large"
          v-if="ambiente == 'A'"
          class="mb-3"
          @click="dialogAggiungiFoto = true"
        >
          AGGIUNGI FOTO
        </v-btn>
        <v-btn
          color="primary"
          @click="associa()"
          rounded
          size="large"
          v-if="checkedPhotos.length != 0"
          class="mb-3"
        >
          Associa
        </v-btn>
        <v-btn
          color="red"
          @click="deleteDialog = true"
          rounded
          size="large"
          v-if="checkedPhotos.length != 0"
        >
          Elimina
        </v-btn>
      </div>
      <v-col cols="10">
        <v-card elevation="2" outlined rounded class="mt-2" width="100%">
          <v-container>
            <v-row>
              <v-col cols="4" lg="5" class="d-flex align-center pb-0">
                <v-date-input
                  v-model="searchDateStart"
                  label="Data inzio"
                  prepend-icon=""
                  append-inner-icon="mdi-calendar"
                  @update:model-value="getPhotos"
                  :min="minSelectableDate"
                  :max="searchDateEnd ? searchDateEnd : currentDate"
                />
              </v-col>
              <v-col cols="4" lg="5" class="d-flex align-center pb-0">
                <v-date-input
                  v-model="searchDateEnd"
                  label="Data fine"
                  prepend-icon=""
                  append-inner-icon="mdi-calendar"
                  @update:model-value="getPhotos"
                  :max="currentDate"
                  :min="searchDateStart"
                />
              </v-col>
              <v-col cols="4" lg="2" class="pb-0">
                <v-select
                  v-model="ambiente"
                  :items="ambienti"
                  label="Ambiente"
                  item-value="value"
                  item-title="text"
                  @update:model-value="cambioAmbiente()"
                  :clearable="false"
                >
                </v-select>
              </v-col>
              <v-col cols="4" lg="5">
                <v-combobox
                  v-model="selectedBarcodes"
                  :items="filteredBarcodes"
                  label="Seleziona o inserisci il barcode"
                  multiple
                  chips
                  closable-chips
                  clearable
                >
                </v-combobox>
              </v-col>
              <v-col cols="4" lg="5">
                <v-combobox
                  v-model="selectedUsers"
                  :items="users"
                  label="Seleziona o inserisci l'utente"
                  multiple
                  chips
                  closable-chips
                  clearable
                >
                </v-combobox>
              </v-col>
              <v-col cols="4" lg="2" class="d-flex align-center">
                <v-btn
                  color="primary"
                  @click="startSearch()"
                  rounded
                >
                  Cerca
                </v-btn>
                <v-btn
                  class="mx-2"
                  icon="mdi-close"
                  density="comfortable"
                  color="grey-darken-3"
                  v-if="
                    this.selectedBarcodes.length == 0 &&
                    this.selectedUsers.length == 0                
                      ? false
                      : true
                  "
                  @click="clearFilter()"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>

      <v-col cols="12" v-if="checkResults">
        <h2 color="secondary">Nessun risultato</h2>
      </v-col>

      <v-container 
        class="ma-0 pa-0" 
        v-for="(barcode, index) in infiniteBarcodes" 
        :key="barcode"
      >
        <v-container
          class="mt-2 d-flex align-center"
          cols="12"
        >
          <h2>{{ barcode }}</h2>

          <v-btn
            class="mx-2"
            icon="mdi-email"
            density="comfortable"
            color="warning"
            @click="hrefEmail(barcode, photos[barcode][0]['ditpbc'])"
          />
        </v-container>

        <v-container>
          <v-card 
            v-for="(photo, j) in filteredPhotos[barcode]"
            :key="`${barcode}${j}`"
            class="ma-2 d-inline-block" 
            width="344"
            @click="showDetail(photo)"
            >
            <v-img
            height="200px"
            contain
            :src="photo['link']"
            class="bg-grey-darken-3"
            style="position: relative"
            >
              <v-checkbox-btn
                v-model="checkedPhotos"
                v-if="ambiente == 'A'"
                color="primary"
                :value="photo"
                :id="j.toString()"
                @click="propagation($event)"
                style="position: absolute; right: 4px; top: 4px"
              ></v-checkbox-btn>
            </v-img>


            <v-card-title>{{ photo["difile"] }}</v-card-title>

            <v-card-text style="height: 100px; color: rgba(0,0,0,.6)" class="text-subtitle-1 d-flex flex-column">
              <p class="ma-0 pa-0">Data e ora: {{ photo["didtai"] }}, {{ photo["dihmai"] }}</p>
              <p class="ma-0 pa-0">Utente: {{ photo["dicdop"] }}</p>
              <p class="ma-0 pa-0 ellipsis" v-if="photo['diarxx']">Inviata a: {{ photo["diarxx"] }}</p>
            </v-card-text>
          </v-card>
        </v-container>
      </v-container>
    </v-row>

    <!-- DELETE -->
    <v-dialog v-model="deleteDialog" max-width="400" persistent>
      <v-card>
        <v-card-text class="d-flex align-center flex-column">
          <v-icon icon="mdi-trash-can" style="font-size: 60px" class="mb-5" color="error" />
          <span style="text-align: center">Sei sicuro di voler eliminare le foto selezionate?</span>
        </v-card-text>
        <v-card-actions class="d-flex align-center">
          <v-btn color="secondary" width="48%" @click="deleteDialog = false">
            Annulla
          </v-btn>
          <v-btn color="primary" width="48%" @click="deleteSelected()">
            Conferma
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ESITO DELETE -->
    <v-dialog v-model="confermaCanc" max-width="400" persistent>
      <v-card>
        <v-card-text class="d-flex align-center flex-column">
          <v-icon :icon="alertType == 'success' ? 'mdi-check-circle' : 'mdi-alert-circle'" style="font-size: 60px" class="mb-5" :color="alertType" />
          <span style="text-align: center">{{ text }}</span>
        </v-card-text>
        <v-card-actions class="d-flex align-center">
          <v-btn color="primary" width="100%" @click="confermaCanc = false">
            Chiudi
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dettaglio foto -->
    <v-dialog v-model="photoDetail">
      <PhotoDetail ref="photoDetail" :photo="photoDetailEl"/>
      <v-btn 
        class="x-button" 
        icon="mdi-close" 
        variant="text"
        @click="photoDetail = false"
        height="40px"
        width="40px"
      />
    </v-dialog>

    <!-- Aggiungi foto -->
    <AggiungiFoto v-model:dialog="dialogAggiungiFoto" @updatePhotos="getPhotos"/>
  </v-container>
</template>

<script>
import router from "../router";
import PhotoDetail from '../components/PhotoDetail.vue';
import AggiungiFoto from '../components/AggiungiFoto.vue'
import { VDateInput } from 'vuetify/labs/VDateInput'

export default {
  name: "Riepilogo",
  components: {
    PhotoDetail,
    AggiungiFoto,
    VDateInput
  },
  data: () => ({
    photos: {},
    barcodes: [],
    users: [],
    checkedPhotos: [],
    controlSelection: true,
    selectedUsers: [],
    selectedBarcodes: [],
    searchBarcode: [],
    searchUsers: [],
    searchDateStart: null,
    searchDateEnd: null,
    ambiente: "A",
    currentDate: null,
    filteredPhotos: {},
    filteredBarcodes: [],
    infiniteBarcodes: [],
    checkResults: false,
    deleteDialog: false,
    confermaCanc: false,
    alertType: "",
    text: "",
    photoDetail: null,
    photoDetailEl: null,
    // Dialog Aggiungi foto
    dialogAggiungiFoto: false
  }),
  mounted() {
    this.$store.state.selectedPhotos = [];
    if (this.$store.state.cd_azienda == "" || this.$store.state.cd_azienda == null) {
      this.$store.state.cd_azienda = localStorage.getItem("cd_azienda");
    }

    this.currentDate = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)

    this.searchDateEnd = new Date(this.currentDate)
    
    var date = new Date(this.currentDate)
    date.setMonth(date.getMonth() - 1);
    this.searchDateStart = date

    this.getPhotos();

    window.addEventListener("scroll", this.handleScroll)
  },
  methods: {
    getPhotos() {
      //  Evito di lanciare la ricerca delle foto se uno delle 2 date è null
      if (!this.searchDateStart || !this.searchDateEnd) {
        return
      }

      this.$store.state.loaded = false

      var startDate = `${this.searchDateStart.getFullYear()}-${(this.searchDateStart.getMonth() + 1).toString().padStart(2,'0')}-${this.searchDateStart.getDate().toString().padStart(2,'0')}`
      var endDate = `${this.searchDateEnd.getFullYear()}-${(this.searchDateEnd.getMonth() + 1).toString().padStart(2,'0')}-${this.searchDateEnd.getDate().toString().padStart(2,'0')}`

      this.axios
        .get(
          `${this.$store.state.endpoint}foto?cd_azienda=${this.$store.state.cd_azienda}&ambiente=${this.ambiente}&data_inizio=${startDate}&data_fine=${endDate}`
        )
        .then((response) => {
          //  Serve per ordinare l'oggetto in base alla data della prima foto nel barcode
          this.photos = response.data;
          this.barcodes = Object.keys(response.data);
          this.users = [];

          //  Ordino barcode per mostrare i dati dal più nuovo al più vecchio
          var barcodes_sort = this.barcodes.map((barcode) => {
            return [barcode, this.photos[barcode][0]["didtai"]]
          })
          this.barcodes = Object.keys(
            Object.fromEntries(barcodes_sort.sort(([,a],[,b]) => b-a))
          )

          for (let i = 0; i < this.barcodes.length; i++) {
            var photosByBarcode = this.photos[this.barcodes[i]];
            for (let j = 0; j < photosByBarcode.length; j++) {
              if (!this.users.includes(photosByBarcode[j]["dicdop"])) {
                this.users.push(photosByBarcode[j]["dicdop"]);
              }
              photosByBarcode[j]["didtai"] = this.formatDate(
                photosByBarcode[j]["didtai"]
              );
              photosByBarcode[j]["dihmai"] = this.formatTime(
                photosByBarcode[j]["dihmai"]
              );
              photosByBarcode[j]["didtim"] = this.formatDate(
                photosByBarcode[j]["didtim"]
              );
              photosByBarcode[j]["dihmim"] = this.formatTime(
                photosByBarcode[j]["dihmim"]
              );
            }
          }
          this.checkedPhotos = [];
          this.$store.state.selectedPhotos = [];

          this.startSearch();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.$store.state.loaded = true
        });
    },
    associa() {
      this.$store.state.selectedPhotos = this.checkedPhotos;
      router.push("/associa");
    },
    // mail() {
    //   localStorage.setItem("ambiente", "A");
    //   this.$store.state.selectedPhotos = this.checkedPhotos;
    //   router.push("/mail");
    // },
    deleteSelected() {
      this.deleteDialog = false;
      for (let i = 0; i < this.checkedPhotos.length; i++) {
        this.axios
          .delete(
            `${this.$store.state.endpoint}foto?cd_azienda=${this.$store.state.cd_azienda}&nome_foto=${this.checkedPhotos[i]["difile"]}`
          )
          .then(() => {
            this.getPhotos();
            this.alertType = "success";
            this.text = "Le foto sono state eliminate con successo";
            this.confermaCanc = true;
          })
          .catch((error) => {
            console.log(error);
            this.alertType = "error";
            this.text = "Impossibile eliminare le foto";
            this.confermaCanc = true;
          });
      }
    },
    formatDate(date) {
      date = date.toString();
      var year = date.slice(0, 4);
      var month = date.slice(-4, -2);
      var day = date.slice(-2);
      return `${day}/${month}/${year}`;
    },
    formatTime(time) {
      time = time.toString().padStart(6, "0");
      time = time.replace(/.{2}/g, "$&:");
      time = time.substr(0, time.length - 1);
      return time;
    },
    // formatDateToIta(date) { // yyyy-mm-dd to gg/mm/aaaa
    //   if (date) {
    //     var array = date.split("-");
    //     return `${array[2]}/${array[1]}/${array[0]}`
    //   }
    //   else {
    //     return '';
    //   }
    // },
    // formatDateToEng(date) { // gg/mm/aaaa to yyyy-mm-dd
    //   if (date) {
    //     var array = date.split("/");
    //     return `${array[2]}-${array[1]}-${array[0]}`
    //   }
    //   else {
    //     return '';
    //   }
    // },
    startSearch() {
      this.filteredBarcodes = [];
      this.infiniteBarcodes = [];
      this.filteredPhotos = {};
      this.searchBarcode = this.barcodes;
      this.checkResults = false;

      if (this.selectedUsers.length > 0) {
        this.searchUsers = this.selectedUsers;
      }
      else {
        this.searchUsers = this.users
      }

      if (this.selectedBarcodes.length > 0) {
        this.searchBarcode = this.selectedBarcodes;
      }
      else {
        this.searchBarcode = this.barcodes
      }

      var date_start = this.searchDateStart ? new Date(this.searchDateStart) : NaN
      var date_end = this.searchDateEnd ? new Date(this.searchDateEnd) : NaN

      for (let i = 0; i < this.barcodes.length; i++) {
        for (let j = 0; j < this.searchBarcode.length; j++) {
          if (this.barcodes[i] == this.searchBarcode[j]) {
            var photosByBarcode = this.photos[this.barcodes[i]];

            for (let j = 0; j < photosByBarcode.length; j++) {
              var date_array = photosByBarcode[j]["didtai"].split("/");
              var date_photo_start = new Date(`${date_array["2"]}-${date_array["1"]}-${date_array["0"]}`);

              if (
                this.searchUsers.includes(photosByBarcode[j]["dicdop"]) &&
                (date_photo_start >= date_start || isNaN(date_start)) &&
                (date_photo_start <= date_end || isNaN(date_end))
              ) {
                if (this.filteredPhotos[this.barcodes[i]] == undefined) {
                  this.filteredPhotos[this.barcodes[i]] = [];
                  this.filteredBarcodes.push(this.barcodes[i]);
                }
                this.filteredPhotos[this.barcodes[i]].push(photosByBarcode[j]);
              }
            }
          }
        }
      }

      if (
        Object.prototype.toString.call(this.filteredPhotos) ===
          "[object Object]" &&
        JSON.stringify(this.filteredPhotos) === "{}"
      ) {
        this.checkResults = true;
      }

      if (this.checkedPhotos.length == 0) {
        this.checkedPhotos = [];
      }

      this.aggiungiDieciBarcodes();
    },
    clearFilter() {
      this.filteredPhotos = this.photos;
      this.filteredBarcodes = this.barcodes;
      this.searchBarcode = this.barcodes;
      this.searchUsers = this.users;

      this.selectedUsers = [];
      this.selectedBarcodes = [];

      if (this.checkedPhotos.length == 0) {
        this.checkedPhotos = [];
      }

      this.checkResults = false;
    
      this.startSearch();
    },
    cambioAmbiente() {
      this.getPhotos();
    },
    hrefEmail(barcode, tipo_barcode) {
      //  Per differenziare 2 barcode uguali ma con tipo diverso (es. bolla / foglio di viaggio) rimuovo la dicitura messa per differenziarli
      if(this.ambiente != 'A' && barcode.indexOf("-") >= 0) {
        barcode = barcode.substr(0, barcode.indexOf("-") - 1)
      }

      const params = {
        jwt: localStorage.getItem("jwt"),
        ambiente: this.ambiente,
        doc: barcode,
        tipo_barcode: tipo_barcode
      }

      var url = `https://${window.location.hostname}:${window.location.port}/#/mail-login?${new URLSearchParams(params).toString()}`
      window.open(url, '_blank')
    },
    showDetail(el) {
      this.photoDetail = true;
      this.photoDetailEl = el;
      this.$nextTick(() => {
        this.$refs.photoDetail.updateDetail()
      })
    },
    propagation(event) {
      event.stopPropagation();
    },
    //  Viene lanciato ogni volta che viene effettuato uno scroll
    handleScroll() {
      let element = this.$refs.scrollComponent.$el
      
      //  Se si è alla fine della pagina...
      if (element.getBoundingClientRect().bottom <= window.innerHeight + 10) {
        this.aggiungiDieciBarcodes();
      }
    },
    //  Aggiungo 10 barcodes alla visualizzazione
    aggiungiDieciBarcodes() {
      this.infiniteBarcodes.push(...this.filteredBarcodes.slice(this.infiniteBarcodes.length, this.infiniteBarcodes.length + 10))
    }
  },
  computed: {
    ambienti() {
      if(!Array.isArray(this.$store.state.ambienti)) {
        return []
      }

      var res = this.$store.state.ambienti.map(el => {
        var val;
  
        switch(el) {
          case "Trasporti": 
            val = "T";
            break;
  
          case "Logistica": 
            val = "L";
            break;
  
          default: 
            return;
        }
  
        return {"text": el, "value": val}
      })
      res.push({"text": "Foto Libera", "value": "A"})

      return res
    },
    minSelectableDate() {
      if (!this.searchDateEnd) {
        return
      }

      var date = new Date(this.searchDateEnd)
      date.setYear(date.getFullYear() - 1);
      return date
    }
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
  }
};
</script>

<style>
.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.mt-card {
  margin-top: 30px;
}

.actions {
  position: fixed;
  right: 16px;
  top: 132px;
}

.v-input__slot {
  justify-content: end;
}

.v-input--selection-controls {
  margin-top: 8px !important;
}

.v-application--is-ltr .v-input--selection-controls__input {
  margin-right: 12px !important;
}

.v-dialog--active {
  position: relative;
}
</style>